import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie';
import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
import { toast } from 'react-toastify';
import { timeout } from '../../utils/mixins';
import { useSelector } from 'react-redux';

export const webAuth = new auth0.WebAuth({
  domain: process.env.GATSBY_AUTH0_DOMAIN ?? "uat-login.hife-coliving.com",
  clientID: process.env.GATSBY_AUTH0_CLIENTID ?? "X7Mc3c55xrzXaHkN54QvhwAHwONYUcuZ",
  redirectUri: process.env.GATSBY_AUTH0_REDIRECTURI, 
  responseType: "id_token",
  cookieDomain: getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!)
});


//Get top level domain
export function getTopLevelDomain(uri: string) {
  let url = process.env.GATSBY_AUTH0_REDIRECTURI! ?? 'https://dev-app.hife-coliving.com';
  url = url.replace(/(^\w+:|^)\/\//, '');
  url = url.split(':')[0];
  const parts = url.split('/');
  let domainValue = parts[0];
  if (domainValue.startsWith('www.')) {
    domainValue = domainValue.substring(4);
  }
  const domainParts = domainValue.split('.');
  if (domainParts.length >= 2) {
    domainValue = '.' + domainParts[domainParts.length - 2] + '.' + domainParts[domainParts.length - 1];
  }
  //console.log("domainValue: ", domainValue)
  return domainValue;
}

export const signinWithGoogleThunk = createAsyncThunk(
  'settings/signinWithGoogleThunk',
  async (arg, { dispatch }) => {
    var accessTokenValue = '';
    var userInfo: string = await new Promise(((resolve, reject) => {
      try {
        webAuth.popup.authorize(
          {
            domain: process.env.GATSBY_AUTH0_DOMAIN ?? "dev-login.hife-coliving.com",
            connection: "google-oauth2",
            responseType: "id_token",
            redirectUri: process.env.GATSBY_AUTH0_REDIRECTURI! ?? 'https://dev-app.hife-coliving.com',
            owp: true
          },
          (err, authResult) => {
            if (err) {
              console.error(err);
              reject()
            } else {
              if (authResult && authResult.idToken != undefined) {
                let tokenDecoded: any = jwt_decode(authResult.idToken);
                accessTokenValue = authResult.idToken ?? '';
                var topLevelDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
                let extraCookieParams = {}
                if (!topLevelDomain.includes("netlify.app")) {
                  extraCookieParams = {
                    domain: topLevelDomain,
                    expires: tokenDecoded.exp
                  }
                }
                if(tokenDecoded?.user_metadata?.idMews){
                  Cookies.set("accessToken", accessTokenValue, extraCookieParams);
                  Cookies.set("idMews", tokenDecoded?.user_metadata?.idMews ?? '', extraCookieParams);
                }
                resolve(JSON.stringify({accessToken: authResult.idToken, mewsId: tokenDecoded?.user_metadata?.idMews }))
              }
            }
          }
        );
      } catch (error) {
        console.log("error", error);
        reject()
      }
    }
    ))
    const userInfoParsed = JSON.parse(userInfo);
    console.log('Google login successfull - got new access token', userInfoParsed )
    setAccessToken(userInfoParsed.accessToken);
    let userBookings = null

    if (Cookies.get("currentResidenceAccessToken")) {
      let currentResidence = JSON.parse(Cookies.get('currentResidence')!);
      userBookings = await dispatch(
       getUserBookingFromSFThunk({auth0Accesstoken: userInfoParsed.accessToken, mewsId: userInfoParsed.mewsId, currentResidence : currentResidence}));
      // console.log('state.residence ===', Cookies.get('currentResidence'))
      // console.log('Google login successfull - got user bookings')
      // check if is colifer
      // if (userBookings && userBookings['Reservations'] && userBookings['Reservations'].length > 0) {
      //   if (state.residence) {
      //     // Loop over all reservations and check if the user has a reservation for the current residence
      //     const currResidenceEnv = state.residence['envList']?.find((env: any) => env.env === process.env.GATSBY_NODE_ENV_NAME);

      //     if (currResidenceEnv) {
      //       for (let i = 0; i < userBookings['Reservations'].length; i++) {
      //         const reservation = userBookings['Reservations'][i];

      //         if (reservation['Residence_MewsId'] === currResidenceEnv.accessTokenMews) {
      //           // If the user has a reservation for the current residence, user is a colifer
      //           isColifer = true;
      //         }
      //       }
      //     }
      //   }
      // }
      // var topLevelDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
      // let extraCookieParams = {}
      // if (!topLevelDomain.includes("netlify.app")) {
      //   extraCookieParams = {
      //     domain: topLevelDomain
      //   }
      // }
      // Cookies.set("isColiver", JSON.stringify(isColifer), extraCookieParams);
    }
    return { "status": true, "newAccessToken": userInfoParsed.accessToken , userBookings, "mewsId": userInfoParsed?.mewsId };
  }
)

export const signinWithEmailThunk = createAsyncThunk(
  'settings/signinWithEmailThunk',
  async (userInfo: any, { getState }) => {
    var accessTokenValue = '';
    let topDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
    console.log("GATSBY_AUTH0_DOMAIN", process.env.GATSBY_AUTH0_DOMAIN);
    console.log("AUTH0_DOMAIN", process.env.AUTH0_DOMAIN);
    console.log("GATSBY_AUTH0_CLIENTID", process.env.GATSBY_AUTH0_CLIENTID);
    console.log("GATSBY_AUTH0_REDIRECTURI", process.env.GATSBY_AUTH0_REDIRECTURI);
    console.log("topDomain", topDomain);
    console.log("PARAMNS", {
      domain: process.env.GATSBY_AUTH0_DOMAIN ?? "uat-login.hife-coliving.com",
      clientID: process.env.GATSBY_AUTH0_CLIENTID ?? "X7Mc3c55xrzXaHkN54QvhwAHwONYUcuZ",
      redirectUri: process.env.GATSBY_AUTH0_REDIRECTURI, 
      responseType: "id_token",
      cookieDomain: getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!)
    })
    console.log("process.env.GATSBY_AUTH0_REDIRECTURI", process.env.GATSBY_AUTH0_REDIRECTURI);
    var newAccessToken: string = await new Promise(((resolve, reject) => {
      try {
        webAuth.login(
          {
            username: userInfo.email,
            password: userInfo.password,
            realm: "Username-Password-Authentication",
            responseType: "id_token",
            redirectUri: process.env.GATSBY_AUTH0_REDIRECTURI! ?? 'https://dev-app.hife-coliving.com',
          },
          (err, authResult) => {
            if (err) {
              reject()
              
            } else {
              if (authResult && authResult.idToken != undefined) {
                let tokenDecoded: any = jwt_decode(authResult.idToken);
                accessTokenValue = authResult.idToken ?? '';
                var topLevelDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
                let extraCookieParams = {}
                if (!topLevelDomain.includes("netlify.app")) {
                  extraCookieParams = {
                    domain: topLevelDomain,
                    expires: tokenDecoded.exp
                  }
                }
                Cookies.set("accessToken", accessTokenValue, extraCookieParams);
                Cookies.set("idMews", tokenDecoded?.user_metadata?.idMews ?? '', extraCookieParams);
                resolve(authResult.idToken)
              }
            }
          }
        );
      } catch (error) {
        reject()
        console.error("Error during login: ", error);
      }
      setAccessToken(newAccessToken);
    }))

    //check if is colifer
    let isColifer = false
    let userBookings = null
    const state = getState();

    if (Cookies.get("currentResidenceAccessToken")) {
      // await getSalesForceToken();
      userBookings = await getUserBookings(newAccessToken)
      console.log('Google login successfull - got user bookings', userBookings)
      if (userBookings && userBookings['Reservations'] && userBookings['Reservations'].length > 0) {
        // isColifer = true;

        if (state.residence) {
          // Loop over all reservations and check if the user has a reservation for the current residence
          const currResidenceEnv = state.residence['envList']?.find((env: any) => env.env === process.env.GATSBY_NODE_ENV_NAME);

          if (currResidenceEnv) {
            for (let i = 0; i < userBookings['Reservations'].length; i++) {
              const reservation = userBookings['Reservations'][i];

              if (reservation['ServiceId'] === currResidenceEnv.accessTokenMews) {
                // If the user has a reservation for the current residence, user is a colifer
                isColifer = true;
              }
            }
          }
        }
      }
      var topLevelDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
      let extraCookieParams = {}
      if (!topLevelDomain.includes("netlify.app")) {
        extraCookieParams = {
          domain: topLevelDomain,
        }
      }
      Cookies.set("isColiver", JSON.stringify(isColifer), extraCookieParams);
    }
    return { "newAccessToken": newAccessToken, isColifer, userBookings };
  }
)

export const signupThunk = createAsyncThunk(
  'settings/signupThunk',
  async (userInfo: any) => {
    let isSuccess = false;
    isSuccess = true;
    await new Promise(((resolve, reject) => {
      try {
        webAuth.signup(
          {
            email: userInfo.email,
            password: userInfo.password,
            userMetadata: { firstname: userInfo.firstname, lastname: userInfo.lastname },
            connection: "Username-Password-Authentication",
          },
          function (err: any, result: any) {
            if (err) {
              isSuccess = true;
              reject(JSON.stringify(err));
            } else {
              isSuccess = true;
              resolve(JSON.stringify(isSuccess));
            }
          }
        );
      } catch (error) {
        console.log("error", error);
        reject()
      }
    }))
    //check if is colifer
    // let isColifer = await getUserBookings(newAccessToken)
    return { "status": true, newAccessToken: '', isColifer: false };
  }
)

export const checkIfUserISColifer = (userBookings: any, currentResidence: any) => {
  let isColifer = false;
  let results = []
  let dateDuJour = new Date();
  console.log("userBookings ", userBookings);
  console.log("currentResidence ", currentResidence);

  if (userBookings && userBookings['Reservations'] && userBookings['Reservations'].length > 0 && currentResidence) {
      const currResidenceEnv = currentResidence.envList?.find((env: any) => env.content.environment === process.env.GATSBY_NODE_ENV_NAME);
      console.log("currResidenceEnv ", currResidenceEnv);
      console.log("process.env.GATSBY_NODE_ENV_NAME ", process.env.GATSBY_NODE_ENV_NAME);
      if (currResidenceEnv) {
        for (let i = 0; i < userBookings['Reservations'].length; i++) {
          const reservation = userBookings['Reservations'][i];
          if (reservation['Residence_MewsId'] === currResidenceEnv.content.mewsPropertyId) {
              // check if display slack icon
              if(reservation.TypedeService == 'Hébergement Moyen Séjour' || reservation.TypedeService == 'Hébergement Long Séjour'){
                let start = new Date(reservation.StartDate);
                let end = new Date(reservation.EndDate);
                if (dateDuJour >= start && dateDuJour <= end) {
                  results.push(reservation);
                }
              }
              // If the user has a reservation for the current residence, user is a colifer
              isColifer = true;
              // break;
          }
        }
    }
  }
  var topLevelDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
  let extraCookieParams = {}
  if (!topLevelDomain.includes("netlify.app")) {
    extraCookieParams = {
      domain: topLevelDomain
    }
  }
  let accessToSlack = results.length > 0;
  Cookies.set("isColiver", JSON.stringify(isColifer), extraCookieParams);
  Cookies.set("accessToSlack", JSON.stringify(accessToSlack), extraCookieParams);
  console.log("results check if access to slack ", results );
  return {isColifer, accessToSlack};

}

export const selectUserBookingThunk = createAsyncThunk(
  'settings/selectUserBookingThunk',
  async (accessToken: any) => {
    let isColifer = false
    let userBookings = null
    if (Cookies.get("currentResidenceAccessToken")) {
      userBookings = await getUserBookings(accessToken)
      console.log('selectUserBookingThunk successfull - got user bookings', userBookings)
      // check if is colifer
      if (userBookings && userBookings['Reservations'] && userBookings['Reservations'].length > 0) {
        isColifer = true;
      }
      var topLevelDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
      let extraCookieParams = {}
      if (!topLevelDomain.includes("netlify.app")) {
        extraCookieParams = {
          domain: topLevelDomain,
        }
      }
      Cookies.set("isColiver", JSON.stringify(isColifer), extraCookieParams);
    }

    return { isColifer, userBookings };
  }
);

const getUserBookings = async (newAccessToken: string) => {
  try {
    const response = await fetch(`${process.env.GATSBY_NODE_ENV_NAME == 'dev' ? 'http://localhost:8000' : ''}/.netlify/functions/isColivers`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "accessToken": newAccessToken,
          "residenceAccessToken": Cookies.get("currentResidenceAccessToken")
        })
      })
    if (!response.ok) {
      console.log(`Request failed with status: ${response.status} - ${response.statusText}`);
      // throw new Error(`Request failed with status: ${response.status}`);
    }
    const responseBody = await response.json();

    return responseBody?.responseData
  } catch (error) {
    console.log("error getUserBookings", error);
  }
}

export const getUserBookingFromSFThunk = createAsyncThunk(
  'settings/getUserBookingFromSFThunk',
  async (params: any) => {
    let isColifer= false;
    let accessToSlack = false;
    var topLevelDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
    let extraCookieParams = {}
    if (!topLevelDomain.includes("netlify.app")) {
      extraCookieParams = {
        domain: topLevelDomain,
      }
    }
  
    try {
      if(!!params.mewsId){
        //get SF token
        const response = await fetch(`${process.env.GATSBY_NODE_ENV_NAME == 'locale' ? 'http://localhost:9001' : ''}/.netlify/functions/getSalesforceToken`,
          {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "mewsId": params.mewsId,
              "accessToken": params.auth0Accesstoken ?? Cookies.get("accessToken"),
              "residenceAccessToken": Cookies.get("currentResidenceAccessToken"),
            })
          })
        if (!response.ok) {
          Cookies.set("isColiver", JSON.stringify(isColifer), extraCookieParams);
          return;
        }
        const responseBody = await response.json();
  
        //get SF reservations
        const responseSF = await fetch(`${process.env.GATSBY_NODE_ENV_NAME == 'locale' ? 'http://localhost:9001' : ''}/.netlify/functions/getBookingSF`,
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "mewsId": params.mewsId,
            "token": responseBody?.responseData.access_token
          })
        })

       

      if (!responseSF.ok) {
        Cookies.set("isColiver", JSON.stringify(isColifer), extraCookieParams);
      }
      const responseSFBody = await responseSF.json();
      console.log("====  responseSF  === ", responseSFBody)
      let checkIfColifer = checkIfUserISColifer(responseSFBody?.responseData, params.currentResidence)
      isColifer = checkIfColifer.isColifer
      accessToSlack = checkIfColifer.accessToSlack
      let resaList = responseSFBody?.responseData;
      console.log("resaList = ", resaList)
      console.log("isColifer = ", isColifer)
      console.log("accessToSlack = ", accessToSlack)
      return { resaList, isColifer, accessToSlack}
  
      }else{
        Cookies.set("isColiver", JSON.stringify(isColifer), extraCookieParams);
        return
      }
  
    } catch (error) {
      console.log("error getSalesForceToken", error);
    }
  }
);


const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    residence: null,
    residences: [],
    selectionItems: 6,
    accessToken: '',
    idMews: null,
    isColifer: false,
    accessToSlack: false,
    userCurrentStay: null,
    userBookings: null,
    closeSignupModal: false,
    onLoading: false,
    hasAlreadyResa: true,
    onError: false,
    targetUri: '',
    showConnexionModal: false,
    closePopupModal: false,
    authError: '',
    authSuccess: '',
    commonConfig: null,
    colifersOnlyLinkClicked: []
  },
  reducers: {
    settingsInit(state, action) {
      state = action.payload
    },
    setCommonConfig(state, action) {
      state.commonConfig = action.payload
    },
    setAccessToken(state, action) {
      console.log("set accessToken store")
      state.accessToken = action.payload
    },
    setResidence(state, action) {
      var topLevelDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
      let extraCookieParams = {}
      if (!topLevelDomain.includes("netlify.app")) {
        extraCookieParams = {
          domain: topLevelDomain,
        }
      }
      Cookies.set("currentResidence", JSON.stringify(action.payload), extraCookieParams);
      state.residence = action.payload
    },
    setResidences(state, action) {
      state.residences = action.payload
    },
    setSelectionItems(state, action) {
      state.selectionItems = action.payload
    },
    setIdMews(state, action) {
      state.idMews = action.payload
      var topLevelDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
      let extraCookieParams = {}
      if (!topLevelDomain.includes("netlify.app")) {
        extraCookieParams = {
          domain: topLevelDomain
        }
      }
      Cookies.set("idMews", JSON.stringify(action.payload), extraCookieParams);
    },
    setIsColifer(state, action) {
      state.isColifer = action.payload
      var topLevelDomain = getTopLevelDomain(process.env.GATSBY_AUTH0_REDIRECTURI!);
      let extraCookieParams = {}
      if (!topLevelDomain.includes("netlify.app")) {
        extraCookieParams = {
          domain: topLevelDomain,
        }
      }
      Cookies.set("isColiver", JSON.stringify(action.payload), extraCookieParams);
    },
    signinWithEmail(state, action) {
      console.log("signinWithMail", action.payload)
    },
    setCloseSignupModal(state, action) {
      state.closeSignupModal = false
    },
    setClosePopupModal(state, action) {
      state.closePopupModal = false
    },
    setOnError(state, action) {
      state.onError = action.payload;
    },
    setTargetRoute(state, action) {
      state.targetUri = action.payload;
    },
    toogleConnexionModal(state, action) {
      state.showConnexionModal = action.payload;
    },
    resetAuthState(state, action) {
      state.authError = ''
      state.authSuccess = ''
    },
    setUserCurrentStay(state, action) {
      state.userCurrentStay = action.payload
    },
    setColifersOnlyLinkClicked(state, action){
      if(action.payload.resetAllData){
        state.colifersOnlyLinkClicked = [];
      }else{
        if(state.colifersOnlyLinkClicked != undefined && state.colifersOnlyLinkClicked.length > 0 ){
          if(!state.colifersOnlyLinkClicked.includes(action.payload.url)){
            state.colifersOnlyLinkClicked.push(action.payload.url);
          }
        }else{
          state.colifersOnlyLinkClicked = [];
          state.colifersOnlyLinkClicked.push(action.payload.url);
        }
      }
    }
  },
  extraReducers: (builder) => {
    //signin with google
    builder.addCase(signinWithGoogleThunk.pending, (state, action) => {
      state.onLoading = true
    })
    builder.addCase(signinWithGoogleThunk.fulfilled, (state, action) => {
      state.onLoading = false
      console.log("signinWithGoogleThunk FULLFILLED", action.payload.mewsId);
      if(action.payload.mewsId){
        console.log("has mews id", action.payload.mewsId)
        const userBookings: any = action.payload.userBookings;
        const newAccessToken: any = action.payload.newAccessToken;
        state.accessToken = newAccessToken
        state.userBookings = userBookings
        state.hasAlreadyResa = true;
      }else{
        state.hasAlreadyResa = false;
      }
    })
    builder.addCase(signinWithGoogleThunk.rejected, (state, action) => {
      state.onLoading = false
    })

    //signin with email/password
    builder.addCase(signinWithEmailThunk.pending, (state, action) => {
      state.onLoading = true
    })
    builder.addCase(signinWithEmailThunk.fulfilled, (state, action) => {
      state.onLoading = false
      setAccessToken(action.payload)
      const newAccessToken: any = action.payload.newAccessToken;
      const isColifer: any = action.payload.isColifer;
      const userBookings: any = action.payload.userBookings;
      state.accessToken = newAccessToken
      state.isColifer = isColifer
      state.closePopupModal = true
      state.userBookings = userBookings
    })
    builder.addCase(signinWithEmailThunk.rejected, (state, action) => {
      state.onLoading = false
      state.onError = true
      state.closePopupModal = true
    })
    builder.addCase(selectUserBookingThunk.fulfilled, (state, action) => {
      const userBookings: any = action.payload.userBookings;
      state.onLoading = false
      state.closePopupModal = true
      state.userBookings = userBookings
    })

    //signup
    builder.addCase(signupThunk.pending, (state, action) => {
      state.onLoading = true
    })
    builder.addCase(signupThunk.fulfilled, (state, action) => {
      state.closeSignupModal = true
      state.onLoading = false
    })
    builder.addCase(signupThunk.rejected, (state, action) => {
      state.closeSignupModal = false
      state.onLoading = false
    })

    //getUserBookingFromSFThunk
    builder.addCase(getUserBookingFromSFThunk.pending, (state, action) => {
      state.onLoading = true
    })
    builder.addCase(getUserBookingFromSFThunk.fulfilled, (state, action) => {
      console.log("getUserBookingFromSFThunk FULLFILLED", action.payload)
      state.isColifer = action.payload?.isColifer ?? false
      state.accessToSlack = action.payload?.accessToSlack ?? false
      state.onLoading = false
    })
    builder.addCase(getUserBookingFromSFThunk.rejected, (state, action) => {
      state.isColifer = false
      state.onLoading = false
    })
  }
})

// Reducers
export default settingsSlice.reducer

// Selectors
export const selectResidence = (state: any) => state.settings.residence
export const selectResidences = (state: any) => state.settings.residences
export const selectSelectionItems = (state: any) => state.settings.selectionItems
export const accessToken = (state: any) => state.settings.accessToken
export const idMews = (state: any) => state.settings.isColifidMewser
export const isColifer = (state: any) => state.settings.isColifer
export const closeSignupModal = (state: any) => state.settings.closeSignupModal
export const onLoading = (state: any) => state.settings.onLoading
export const onError = (state: any) => state.settings.onError
export const targetUri = (state: any) => state.settings.targetUri
export const closePopupModal = (state: any) => state.settings.closePopupModal
export const showConnexionModal = (state: any) => state.settings.showConnexionModal
export const authError = (state: any) => state.settings.authError
export const authSuccess = (state: any) => state.settings.authSuccess
export const commonConfig = (state: any) => state.settings.commonConfig
export const selectUserBookings = (state: any) => state.settings.userBookings
export const selectUserCurrentStay = (state: any) => state.settings.userCurrentStay
export const accessToSlack = (state: any) => state.settings.accessToSlack
export const hasAlreadyResa = (state: any) => state.settings.hasAlreadyResa
export const colifersOnlyLinkClicked = (state: any) => state.settings.colifersOnlyLinkClicked

// Actions
export const { settingsInit, setAccessToken, setSelectionItems, setResidence, setResidences, setIdMews, setIsColifer, signinWithEmail, setCloseSignupModal, resetAuthState, toogleConnexionModal, setClosePopupModal, setOnError, setTargetRoute, setCommonConfig, setUserCurrentStay, setColifersOnlyLinkClicked } = settingsSlice.actions
