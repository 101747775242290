module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hife coliving","short_name":"Hife","start_url":"/","background_color":"#f5aaa2","display":"standalone","icon":"src/images/favicon-source.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"39f0eca48b9ebf450dc32d0d2fc96e5d"},
    },{
      plugin: require('../plugins/gatsby-source-umbraco/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"dvrol2TKxrSDpOaC2CBd","host":"cdn.umbraco.io","projectAlias":"hife-coliving"},
    },{
      plugin: require('../plugins/gatsby-plugin-intl-kley/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","fr"],"defaultLanguage":"fr","redirect":true,"redirectDefaultLanguageToRoot":false,"ignoredPaths":[],"fallbackLanguage":"fr","localeJsonSourceName":"intl"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PBJGVNWQ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
